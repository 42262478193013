.landing-bg {
  background: rgb(253, 98, 87);
  background: radial-gradient(
    circle,
    rgba(253, 98, 87, 0.8995973389355743) 30%,
    rgba(31, 41, 55, 1) 100%
  );
}
.k-grid tbody tr:hover {
  background-color: none;
}
.k-form-error {
  width: 240px;
  font-size: 14px !important;
}